<template>
  <a
    @click.prevent="$emit('input', !value)"
    class="flex items-center m-2 cursor-pointer cm-toggle-wrapper"
  >
    <div class="rounded-full w-8 h-4 p-0.5 bg-gray-300" :class="{ 'bg-green-500': value }">
      <div
        class="rounded-full w-3 h-3 bg-white transform mx-auto duration-300 ease-in-out"
        :class="value ? 'translate-x-2' : '-translate-x-2'"
      ></div>
    </div>
  </a>
</template>

<script>
export default {
  name: 'toggle',
  props: ['value'],
  data() {
    return {}
  },
}
</script>

<style></style>
